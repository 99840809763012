import {ComponenteCommand} from './componente-command';
import {TipoApplicatore} from '../tipo-applicatore';
import {ModelloApplicatore} from '../modello-applicatore';

export class ComponenteDefaultApplicatoreCommand extends ComponenteCommand {
    tipoApplicatore: TipoApplicatore;
    modelloApplicatore: ModelloApplicatore;
    componenteDefaultApplicatoreId: number;
    
    constructor(nome: string, 
        codice: string,
        sigla: string,
        indice: number,
        formula: string,
        dipendenze: string,
        misura: number,
        tipoApplicatore: TipoApplicatore,
        modelloApplicatore: ModelloApplicatore,
        componenteDefaultApplicatoreId: number) {
        super(nome, codice, sigla, indice, formula, dipendenze, misura);
        this.tipoApplicatore = tipoApplicatore;
        this.modelloApplicatore = modelloApplicatore;
        this.componenteDefaultApplicatoreId = componenteDefaultApplicatoreId;
    }
}
