import {AbstractCommand} from './abstract-command';

export class ComponenteCommand extends AbstractCommand {
    nome: string;
    codice: string;
    sigla: string;
    indice: number;
    formula: string;
    dipendenze: string;
    misura: number;

    constructor(nome: string,
                codice: string,
                sigla: string,
                indice: number,
                formula: string,
                dipendenze: string,
                misura: number
    ) {
        super();
        this.nome = nome;
        this.codice = codice;
        this.sigla = sigla;
        this.indice = indice;
        this.formula = formula;
        this.dipendenze = dipendenze;
        this.misura = misura;
    }
}
