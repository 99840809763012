import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Applicatore} from '../model/query/applicatore';
import {ComponenteApplicatore} from '../model/query/componente-applicatore';
import {ApplicatoreCommand} from '../model/command/applicatore-command';
import {ComponenteApplicatoreCommand} from '../model/command/componente-applicatore-command';
import {QueryResult} from '../model/query/query-result';
import {ResultUrl} from '../model/result-url';

@Injectable()
export class ApplicatoriService extends BaseService<Applicatore> {
    private storageBasePath: string = environment.storageUrl;

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('applicatori');
    }

    createApplicatore(command: ApplicatoreCommand): Observable<Applicatore> {
        return super.create(command).pipe(
            switchMap((id: number) => super.get(id.toString())),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    getList(): Observable<QueryResult<Applicatore>> {
        return this.httpClient.get<QueryResult<Applicatore>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + ' - get', null))
        );
    }

    getComponenti(id: string): Observable<Array<ComponenteApplicatore>> {
        return this.httpClient.get<Array<ComponenteApplicatore>>(this.apiUrl + '/' + id + '/componenti').pipe(
            catchError(this.handleError(this.apiUrl + ' - componenti', null))
        );
    }

    getComponente(id: string, componenteId: string): Observable<ComponenteApplicatore> {
        return this.httpClient.get<ComponenteApplicatore>(this.apiUrl + '/' + id + '/componenti/' + componenteId).pipe(
            catchError(this.handleError(this.apiUrl + ' - componente', null))
        );
    }

    calcolaMisure(id: string): Observable<ComponenteApplicatore> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/calcola-misure', {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - calcola misure', null))
        );
    }

    addComponente(id: string, componente: ComponenteApplicatoreCommand): Observable<number> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/componenti', componente, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>) => of(this.extractIdFromLocation(res))),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    putComponente(id: string, componenteId: string, componente: ComponenteApplicatoreCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/' + id + '/componenti/' + componenteId, componente).pipe(
            catchError(this.handleError(this.apiUrl + ' - put componente', null))
        );
    }

    deleteComponente(id: string, componenteId: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/' + id + '/componenti/' + componenteId).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete componente', null))
        );
    }

    getIdByCodice(codice: string): Observable<number> {
        return this.httpClient.get<number>(this.apiUrl + '/get-id-by-codice/' + codice).pipe(
            catchError(this.handleError(this.apiUrl + ' - get-id-by-codice', null))
        );
    }

    aggiungiDocumento(id: string, tipo: string, file: File, fileName: string): Observable<number> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/documento', {fileName: fileName, tipo: tipo}, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                return this.httpClient.get<Applicatore>(this.apiUrl + '/' + id);
            }),
            switchMap((applicatore: Applicatore) => {
                if (applicatore != null) {
                    return this.httpClient.put(this.storageBasePath + applicatore["file" + tipo + "Url"], file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError(' - aggiungiDocumento', null))
        );
    }

    getDocumentDownloadUrl(id: string, tipo: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + id + '/download-url/' + tipo).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getDocumentDownloadUrl', null))
        );
    }

    aggiungiDocumentoComponente(id: string, componenteId: string, tipo: string, file: File, fileName: string): Observable<number> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/componenti/' + componenteId + '/documento', {fileName: fileName, tipo: tipo}, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                return this.httpClient.get<ComponenteApplicatore>(this.apiUrl + '/' + id + '/componenti/' + componenteId);
            }),
            switchMap((componente: ComponenteApplicatore) => {
                if (componente != null) {
                    return this.httpClient.put(this.storageBasePath + componente["file" + tipo + "Url"], file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(componenteId);
            }),
            catchError(this.handleError(' - aggiungiDocumentoComponente', null))
        );
    }

    getComponenteDocumentDownloadUrl(id: string, componenteId: string, tipo: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + id + '/componenti/' + componenteId + '/download-url/' + tipo).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getComponenteDocumentDownloadUrl', null))
        );
    }

}
