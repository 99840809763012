import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { ClrDatagridStateInterface } from '@clr/angular';
import { QueryResult } from '../../../model/query/query-result';
import { AbstractBean } from '../../../model/query/abstract-bean';
import { ClrLoadingState } from '@clr/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../core/globals.service';
import { UtilsService } from '../../../core/utils.service';
import { ComponentiDefaultApplicatoriService } from '../../../core/componenti-default-applicatori.service';
import { ComponenteDefaultApplicatore } from '../../../model/query/componente-default-applicatore';
import { ComponenteDefaultApplicatoreCommand } from '../../../model/command/componente-default-applicatore-command';
import { Action } from '../../../model/action';

@Component({
    selector: 'app-componente-default-applicatore-detail',
    templateUrl: './componente-default-applicatore-detail.component.html',
    styleUrls: ['./componente-default-applicatore-detail.component.css']
})
export class ComponenteDefaultApplicatoreDetailComponent implements OnInit {
    modelliApplicatoreDescription: any;
    tipiApplicatoreDescription: any;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    selectedObjects = [];
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    fileDisegnoName: string;
    fileDisegno: File;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public utilsService: UtilsService,
        private componentiDefaultApplicatoriService: ComponentiDefaultApplicatoriService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.modelliApplicatoreDescription = utilsService.getModelliApplicatoreDescription();
        this.tipiApplicatoreDescription = utilsService.getTipiApplicatoreDescription();
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id !== 'new') {
            this.componentiDefaultApplicatoriService.get(this.id).subscribe(componente => {
                this.fileDisegnoName = componente.fileDisegnoName;
                this.initializeFormFields(componente);
                if (this.action != null && this.action == Action.VIEW) {
                    this.readOnly = true;
                }
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: ComponenteDefaultApplicatoreCommand = 
            new ComponenteDefaultApplicatoreCommand(formValue.nome, formValue.codice, formValue.sigla, formValue.indice, formValue.formula, formValue.dipendenze, 
                formValue.misura, formValue.tipo, formValue.modello, null);
        
        if (!this.id || this.id === 'new') {
            this.componentiDefaultApplicatoriService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (res) {
                    this.alertMessage = 'ComponenteDefaultApplicatore creato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.componentiDefaultApplicatoriService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.alertMessage = 'ComponenteDefaultApplicatore aggiornato!';
                this.alertClosed = false;
            });
        }
    }

    createForm(): void {
        const formGroup = {
            modello: ['', [Validators.required]],
            tipo: ['', [Validators.required]],
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            codice: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            sigla: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            indice: [{ value: '' }],
            formula: ['', [Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            dipendenze: ['', [Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            misura: [{ value: '' }]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(componente: ComponenteDefaultApplicatore): void {
        let formValues;

        if (componente) {
            formValues = {
                modello: componente.modelloApplicatore.toString(),
                tipo: componente.tipoApplicatore.toString(),
                nome: componente.nome,
                codice: componente.codice,
                sigla: componente.sigla,
                indice: componente.indice ? componente.indice : '',
                formula: componente.formula ? componente.formula : '',
                dipendenze: componente.dipendenze ? componente.dipendenze : '',
                misura: componente.misura ? componente.misura : ''
            };
        } else {
            formValues = {
                modello: '0',
                tipo: '0',
                nome: '',
                codice: '',
                sigla: '',
                indice: '',
                formula: '',
                dipendenze: '',
                misura: ''
            };
        }
        this.form.setValue(formValues);
    }

    onDownloadDocumento() {
        this.componentiDefaultApplicatoriService.getDisegnoDownloadUrl(this.id.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onFileChange(event: any) {
        this.fileDisegno = event.target.files[0];
    }

    uploadFile(): void {
        let fileName = "Disegno-" + this.id;

        this.componentiDefaultApplicatoriService.aggiungiDisegno(this.id, this.fileDisegno, fileName).subscribe(() => {
            this.fileDisegnoName = fileName;
        });
    }
}
