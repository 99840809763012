import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {ComponenteDefaultApplicatore} from '../model/query/componente-default-applicatore';
import {QueryResult} from '../model/query/query-result';
import {ResultUrl} from '../model/result-url';

@Injectable()
export class ComponentiDefaultApplicatoriService extends BaseService<ComponenteDefaultApplicatore> {
    private storageBasePath: string = environment.storageUrl;
    
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('componenti-default-applicatore');
    }

    aggiungiDisegno(id: string, file: File, fileName: string): Observable<number> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/disegno', {fileName: fileName}, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                return this.httpClient.get<ComponenteDefaultApplicatore>(this.apiUrl + '/' + id);
            }),
            switchMap((componente: ComponenteDefaultApplicatore) => {
                if (componente != null) {
                    return this.httpClient.put(this.storageBasePath + componente["fileDisegnoUrl"], file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError(' - aggiungiDisegno', null))
        );
    }

    getDisegnoDownloadUrl(id: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + id + '/disegno-download-url').pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getDisegnoDownloadUrl', null))
        );
    }
}
