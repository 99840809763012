import {ComponenteCommand} from './componente-command';

export class ComponenteApplicatoreCommand extends ComponenteCommand {
    applicatoreId: number;
    componenteApplicatoreId: number;
    
    constructor(nome: string, 
        codice: string,
        sigla: string,
        indice: number,
        formula: string,
        dipendenze: string,
        misura: number,
        applicatoreId: number,
        componenteApplicatoreId: number) {
        super(nome, codice, sigla, indice, formula, dipendenze, misura);
        this.applicatoreId = applicatoreId;
        this.componenteApplicatoreId = componenteApplicatoreId;
    }
}
