import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthService} from './auth.service';
import {AuthGuard} from './auth.guard';
import {CheckPathAuthorizationService} from './check-path-authorization.service';
import {AuthInterceptor} from './auth.interceptor';
import {UploadService} from './upload.service';
import {MeService} from './me.service';
import {AmministratoriService} from './amministratori.service';
import {UtilsService} from './utils.service';
import {Globals} from './globals.service';
import {NazioniService} from './nazioni.service';
import {OperatoriService} from './operatori.service';
import {ClientiService} from './clienti.service';
import {CostruttoriService} from './costruttori.service';
import {TerminaliService} from './terminali.service';
import {ApplicatoriService} from './applicatori.service';
import {PresseService} from './presse.service';
import {OrdiniService} from './ordini.service';
import {NomiComponenteService} from './nomi-componente.service';
import {TestService} from './test.service';
import {ComponentiDefaultApplicatoriService} from './componenti-default-applicatori.service';

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AuthGuard,
        CheckPathAuthorizationService,
        UploadService,
        MeService,
        AmministratoriService,
        NazioniService,
        OperatoriService,
        ClientiService,
        CostruttoriService,
        TerminaliService,
        ApplicatoriService,
        PresseService,
        OrdiniService,
        NomiComponenteService,
        TestService,
        ComponentiDefaultApplicatoriService,
        UtilsService,
        Globals
    ]
})
export class CoreModule {
}
